html {
  overflow-x: hidden;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

* {
  margin: 0;
  box-sizing: border-box;
  outline: none;
  border: none;
  font-family: 'Pretendard';
}

body {
  zoom: 1;
  transform: scale(1);
}

:root {
  --user-icon-url: url("https://apple.o2o.kr/img2/viewbeauty/viewbeauty_02.svg");
  --bot-icon-url: url("https://banana.o2o.kr/img2/skylife_poc/img_icon_02.svg");
}

.wrap {
  text-align: center;
  margin: 0 auto;
  max-width: 480px;
  overflow: hidden;
}

.container {
  height: calc(var(--vh, 1vh) * 100);
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 480px;
  position: relative;
}

.msger {
  width: 100%;
  max-width: 760px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 15px 15px -5px rgba(0, 0, 0, 0.2);
  position: relative;
  color: var(--sky-body-font);
}

.msger-chat-frame {
  height: calc(var(--vh, 1vh) * 100 - 50px);
  position: relative;
  display: flex;
  flex-direction: column;
  background: #FBF7FC;
}

.home-container p {
  color: rgb(185, 104, 184);
  text-shadow: 1px 1px 5px black;
}

.header-logo-frame {
  float: left;
  margin-left: 15px;
  margin-top: 5px;
}

.msger-chat-prompt {
  overflow-y: auto;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 0 4px;
  margin-top: 20px;
}

.scroll-prompt {
  padding: 0 20px;
}

.user-say-text {
  text-align: justify;
}
.user-say-text p {
  margin-bottom: 5px;
}
.msg-time {
  text-align: right;
  font-size: 12px;
  margin-top: 5px;
}

.msger-form {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background: none;
  background: #2d404d03;
}

.chips-wrapper {
  display: flex;
  white-space: nowrap;
  padding: 0.7rem 1rem;
  align-items: center;
  overflow-y: hidden;
  font-size: 12px;
  line-height: 14px;
}

.chips-wrapper::-webkit-scrollbar {
  display: none;
}

.chips-wrapper {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.rounded-chip {
  border-radius: 100px;
  background-color: #F2EAF3;
  margin-left: 5px;
  font-size: 14px;
  height: 34px;
  padding: 8px 15px;
  cursor: pointer;
  backdrop-filter: blur(10px);
  box-shadow: 4px 4px 12px rgba(13, 21, 26, 0.2);
  color: black;
}

.rounded-chip-admin {
  float: right;
  display: flex;
  padding: 11px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  background: var(--view_03, #7C2B84);
  margin-right: 5px;
  color: var(--white, #FFF);
  text-align: center;
  font-family: "Noto Sans";
  font-size: 14px;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: -0.6px;
}

.msger-inputarea {
  background: rgba(0, 0, 0, 0.2);
  padding: 14px;
  font-size: 14px;
  width: 100%;
  color: #FFFFFF;
}

.msger-input {
  background: white;
  padding: 14px;
  font-size: 14px;
  width: 65%;
  height: 40px;
  border-radius: 100px;
  margin-left: 5px;
  color: black;
  float: left;
}

.send-btn {
  background: none;
  margin-top: auto;
  float: left;
  margin-left: 5px;
}

.user-say {
  color: white;
}

.msg-bubble {
  width: fit-content;
  max-width: 80%;
  margin-bottom: 5px;
  padding: 10px;
  border-radius: 10px;
}

.msg-right {
  margin-top: 20px;
  align-self: flex-end;
  margin-left: auto;
  border-radius: 10px 10px 0px 10px;
  border: 1px solid var(--gray_01, #CCC7CC);
  background: var(--view_02, #fbffd3);
  box-shadow: 12px 12px 24px 0px rgba(124, 43, 132, 0.08);
}

.msg-left {
  align-self: flex-start;
  margin-right: auto;
  border-radius: 10px 10px 10px 0px;
  border: 1px solid var(--gray_01, #CCC7CC);
  background: var(--white, #FFF);
  box-shadow: 12px 12px 24px 0px rgba(124, 43, 132, 0.08);
}

.greeting {
  width: fit-content;
  padding: 10px;
  margin: 5px;
  border-radius: 10px 10px 10px 0px;
  border: 1px solid var(--gray_01, #CCC7CC);
  background: var(--white, #FFF);
  box-shadow: 12px 12px 24px 0px rgba(124, 43, 132, 0.08);
}

.banner {
  width: fit-content;
  padding: 10px;
  border-radius: 10px 10px 10px 10px;
  margin: 10px auto;
  background-color: rgba(255, 218, 185, 0.7);
}

.msg-icon {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  align-self: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.msg-right .msg-icon {
  order: -1;
}

.msg-left .msg-icon {
  margin-left: 10px;
  background-image: var(--bot-icon-url);
}

.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  background-color: rgba(135, 206, 235, 0.3);
}

.loading-spinner {
  width: 150px;
  height: 150px;
}

.chat-container {
  transition: filter 0.3s;
  position: relative;
}

.blurred {
  filter: blur(0.5px);
}

.blurred-input {
  filter: blur(1.5px);
}

.modal {
  /* position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  padding: 24px 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;
  border-radius: 20px;
  background: var(--white, #FFF);
  max-width: 350px;
  z-index: 1001; */

  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  padding: 24px 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;
  border-radius: 20px;
  background: var(--white, #FFF);
  max-width: 350px;
  z-index: 1002; /* Ensure the modal is above the blurred background */
  filter: none; /* Ensure no blur is applied to the modal */
}

.modal-header {
  display: flex;
  float: left;
  width: 100%;
  text-align: left;
}

.modal-title {
  margin: 15px 0px 15px 0px;
  color: var(--gray_03, #3C3E3F);
  font-family: "Noto Sans";
  font-size: 20px;
  font-weight: 700;
  line-height: 100%;
  letter-spacing: -0.6px;
}

.modal-subtitle {
  color: var(--gray_03, #3C3E3F);
  font-family: "Noto Sans";
  font-size: 16px;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: -0.6px;
}

.close-button {
  float: right;
  cursor: pointer;
}

button {
  margin-top: 10px;
}

.modal-submit {
  display: flex;
  margin: 0 auto;
  margin-top: 15px;
  width: 100%;
  padding: 14px 0px 15px 0px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background: var(--view_03, #7C2B84);
  color: var(--white, #FFF);
  text-align: center;
  font-family: "Noto Sans";
  font-size: 16px;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: -0.6px;
}

.modal-input {
  display: flex;
  height: 56px;
  padding: 0px 133px 0px 16px;
  align-items: center;
  border-radius: 10px;
  border: 1px solid var(--gray_02, #87898C);
  background: var(--white, #FFF);
}

.modal-input-text {
  float: left;
  margin: 10px 0px 10px 0px;
  color: var(--gray_03, #3C3E3F);
  font-family: "Noto Sans KR";
  font-size: 14px;
  font-weight: 700;
  line-height: 100%;
  letter-spacing: -0.6px;
}


.blurred-input-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.20);
  filter: blur(5px);
  z-index: 1000; /* Ensure this is below the modal */
}


/* App.css */

.popup-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.popup {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.popup button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #7C2B84;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.popup button:hover {
  background-color: #ebc9ee;
}

.blurred {
  filter: blur(0.5px);
}
